import { FC } from "react";
import img1 from "../../../assets/images/impact/1.jpg";
import img2 from "../../../assets/images/impact/2.jpg";
import img3 from "../../../assets/images/impact/3.jpg";
import img4 from "../../../assets/images/impact/4.jpg";
import img5 from "../../../assets/images/impact/5.jpg";
import { Blue, Yellow } from "../../../assets/svg/ImpactSvg";
import styles from "./impactStyles.module.scss";

const Impact: FC = (): JSX.Element => {
	return (
		<section id="wrapper" className={styles.impact}>
			<div className={styles.left}>
				<div className={styles.header}>
					<h4>Impact</h4>

					<p className={styles.subHeader}>
						Here&lsquo;s a glimpse of the tangible difference we make:
					</p>
				</div>

				<ul>
					<li>
						Empowering Education: We&lsquo;ve provided scholarships and
						educational workshops to over [Number] young people, equipping them
						with essential skills for academic success and future careers.
					</li>

					<li>
						Building a Healthier Future: Our outreach programs have delivered
						vital healthcare services to [Number] individuals in underserved
						communities, promoting better health and well-being.
					</li>

					<li>
						Investing in Communities: We&lsquo;ve partnered with local
						organizations on [Number] community development projects, creating
						lasting improvements and empowering residents to build a brighter
						future.
					</li>
				</ul>

				<p style={{ padding: "0 19px" }}>
					These are just a few examples of how AMGH is making a difference. The
					stories of our beneficiaries are a testament to the power of your
					support. Join us in creating a brighter future for young Africans.
					Donate today and help us continue our life-changing work.
				</p>
			</div>

			<div className={styles.right}>
				<div className={styles.gridContainer}>
					<div className={`${styles.gridItem} ${styles.topImg}`}>
						<div
							className={styles.image_container}
							style={{ border: "1.5px solid #EA5D0B" }}
						>
							<img className={styles.styled_image} src={img1} alt="Impact 1" />
						</div>

						<img style={{ scale: 0.7 }} src={img2} alt="Impact 2" />
					</div>

					<div className={`${styles.gridItem} ${styles.bottomImg}`}>
						<img src={img3} alt="Impact 3" />

						<div
							className={styles.image_container}
							style={{ height: "80%", border: "1.5px solid #FF0000" }}
						>
							<img className={styles.styled_image} src={img4} alt="Impact 4" />
						</div>
					</div>

					<div className={`${styles.gridItem} ${styles.middleImg}`}>
						<div
							className={`${styles.image_container}`}
							style={{ border: "1.5px solid #0983B7" }}
						>
							<img className={styles.styled_image} src={img5} alt="Impact 5" />
						</div>
					</div>
				</div>

				<Yellow className={styles.yellow} />

				<Blue className={styles.blue} />
			</div>
		</section>
	);
};

export default Impact;
