import { FC } from "react";
import img1 from "../../assets/images/impact/1.jpg";
import img2 from "../../assets/images/impact/2.jpg";
import img3 from "../../assets/images/impact/3.jpg";
import img4 from "../../assets/images/impact/4.jpg";
import img5 from "../../assets/images/impact/5.jpg";
import img from "../../assets/images/team/all.jpg";

import { Link } from "react-router-dom";
import { Blue, Yellow } from "../../assets/svg/ImpactSvg";
import { Facebook, Instagram, Twitter, Youtube } from "../../assets/svg/Social";
import siteMetadata from "../../data/siteMetaData";
import styles from "./contactStyles.module.scss";

const Contact: FC = (): JSX.Element => {
	return (
		<section className={styles.contact}>
			<div className={styles.top}>
				<div className={styles.overLay} />

				<img alt="overLay" src={img} className={styles.overLay_img} />

				<h4>We&apos;d love to hear from you, Get in touch today</h4>
			</div>

			<div id="wrapper" className={styles.bottom}>
				<div className={styles.left}>
					<h3>Contact Us</h3>

					<p style={{ maxWidth: "649px" }}>
						Here&apos;s a few ways to get in touch. Ask us about anything about
						our Programs, Donation and Volunteering . we&apos;ll get back to you
						quickly, our contact details is below.
					</p>

					<br />
					<br />

					<div>
						<svg
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#clip0_194_702)">
								<path
									d="M21.5531 4.67743C21.4622 4.66807 21.3706 4.66807 21.2798 4.67743H2.6131C2.49346 4.67928 2.37462 4.69722 2.25977 4.73077L11.8931 14.3241L21.5531 4.67743Z"
									// fill="#F8F8F8"
								/>
								<path
									d="M22.5404 5.60425L12.8338 15.2709C12.584 15.5192 12.246 15.6586 11.8938 15.6586C11.5415 15.6586 11.2036 15.5192 10.9538 15.2709L1.33378 5.67758C1.3042 5.78628 1.28852 5.89828 1.28711 6.01091V19.3442C1.28711 19.6979 1.42759 20.037 1.67763 20.2871C1.92768 20.5371 2.26682 20.6776 2.62044 20.6776H21.2871C21.6407 20.6776 21.9799 20.5371 22.2299 20.2871C22.48 20.037 22.6204 19.6979 22.6204 19.3442V6.01091C22.6151 5.87202 22.5881 5.7348 22.5404 5.60425ZM3.53378 19.3442H2.60711V18.3909L7.45378 13.5842L8.39378 14.5242L3.53378 19.3442ZM21.2738 19.3442H20.3404L15.4804 14.5242L16.4204 13.5842L21.2671 18.3909L21.2738 19.3442Z"
									// fill="#F8F8F8"
								/>
							</g>
							<defs>
								<clipPath id="clip0_194_702">
									<rect
										width="24"
										height="24"
										fill="white"
										transform="translate(0 0.67749)"
									/>
								</clipPath>
							</defs>
						</svg>

						<p>amnharvesters@gmail.com</p>
					</div>

					<div>
						<svg
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18.3284 23.1775C17.4134 23.1775 16.1281 22.8466 14.2034 21.7713C11.8629 20.4588 10.0526 19.2471 7.72481 16.9254C5.48043 14.6824 4.38825 13.2302 2.85965 10.4486C1.13278 7.30801 1.42715 5.66176 1.75622 4.95817C2.14809 4.11723 2.72653 3.61426 3.47419 3.11504C3.89885 2.83681 4.34825 2.5983 4.81669 2.40254C4.86356 2.38239 4.90715 2.36317 4.94606 2.34582C5.17809 2.24129 5.52965 2.08332 5.97497 2.25207C6.27215 2.36364 6.53747 2.59192 6.95278 3.00207C7.8045 3.84207 8.9684 5.71286 9.39778 6.63161C9.68606 7.25082 9.87684 7.65957 9.87731 8.11801C9.87731 8.65473 9.60731 9.06864 9.27965 9.51536C9.21825 9.59926 9.15731 9.67942 9.09825 9.75723C8.74153 10.226 8.66325 10.3614 8.71481 10.6033C8.81934 11.0894 9.59887 12.5364 10.88 13.8147C12.1611 15.093 13.5664 15.8233 14.0543 15.9274C14.3065 15.9813 14.4448 15.8997 14.9286 15.5304C14.9979 15.4774 15.0692 15.4225 15.1437 15.3677C15.6434 14.996 16.0381 14.733 16.5622 14.733H16.565C17.0211 14.733 17.4115 14.9308 18.0584 15.2571C18.9022 15.6827 20.8292 16.8316 21.6743 17.6843C22.0854 18.0986 22.3147 18.363 22.4267 18.6597C22.5954 19.1064 22.4365 19.4566 22.3329 19.691C22.3156 19.7299 22.2964 19.7725 22.2762 19.8199C22.0789 20.2875 21.839 20.7359 21.5595 21.1596C21.0612 21.9049 20.5564 22.4819 19.7136 22.8743C19.2808 23.079 18.8071 23.1827 18.3284 23.1775Z"
								// fill="#F8F8F8"
							/>
						</svg>

						<p>(+234) 813 753 5050</p>
					</div>

					<div>
						<svg
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18.3284 23.1775C17.4134 23.1775 16.1281 22.8466 14.2034 21.7713C11.8629 20.4588 10.0526 19.2471 7.72481 16.9254C5.48043 14.6824 4.38825 13.2302 2.85965 10.4486C1.13278 7.30801 1.42715 5.66176 1.75622 4.95817C2.14809 4.11723 2.72653 3.61426 3.47419 3.11504C3.89885 2.83681 4.34825 2.5983 4.81669 2.40254C4.86356 2.38239 4.90715 2.36317 4.94606 2.34582C5.17809 2.24129 5.52965 2.08332 5.97497 2.25207C6.27215 2.36364 6.53747 2.59192 6.95278 3.00207C7.8045 3.84207 8.9684 5.71286 9.39778 6.63161C9.68606 7.25082 9.87684 7.65957 9.87731 8.11801C9.87731 8.65473 9.60731 9.06864 9.27965 9.51536C9.21825 9.59926 9.15731 9.67942 9.09825 9.75723C8.74153 10.226 8.66325 10.3614 8.71481 10.6033C8.81934 11.0894 9.59887 12.5364 10.88 13.8147C12.1611 15.093 13.5664 15.8233 14.0543 15.9274C14.3065 15.9813 14.4448 15.8997 14.9286 15.5304C14.9979 15.4774 15.0692 15.4225 15.1437 15.3677C15.6434 14.996 16.0381 14.733 16.5622 14.733H16.565C17.0211 14.733 17.4115 14.9308 18.0584 15.2571C18.9022 15.6827 20.8292 16.8316 21.6743 17.6843C22.0854 18.0986 22.3147 18.363 22.4267 18.6597C22.5954 19.1064 22.4365 19.4566 22.3329 19.691C22.3156 19.7299 22.2964 19.7725 22.2762 19.8199C22.0789 20.2875 21.839 20.7359 21.5595 21.1596C21.0612 21.9049 20.5564 22.4819 19.7136 22.8743C19.2808 23.079 18.8071 23.1827 18.3284 23.1775Z"
								// fill="#F8F8F8"
							/>
						</svg>

						<p>(+234) 808 805 9045</p>
					</div>

					<div className={styles.social}>
						<Link to={siteMetadata.facebook}>
							<Facebook />
						</Link>

						<Link to={siteMetadata.instagram}>
							<Instagram />
						</Link>

						<Link to={siteMetadata.twitter} className={styles.twitter}>
							<Twitter />
						</Link>

						<Link to={siteMetadata.youtube}>
							<Youtube />
						</Link>
					</div>
				</div>

				<div className={styles.right}>
					<div className={styles.gridContainer}>
						<div className={`${styles.gridItem} ${styles.topImg}`}>
							<div
								className={styles.image_container}
								style={{ border: "1.5px solid #EA5D0B" }}
							>
								<img
									className={styles.styled_image}
									src={img1}
									alt="Impact 1"
								/>
							</div>

							<img style={{ scale: 0.7 }} src={img2} alt="Impact 2" />
						</div>

						<div className={`${styles.gridItem} ${styles.bottomImg}`}>
							<img src={img3} alt="Impact 3" />

							<div
								className={styles.image_container}
								style={{ height: "80%", border: "1.5px solid #FF0000" }}
							>
								<img
									className={styles.styled_image}
									src={img4}
									alt="Impact 4"
								/>
							</div>
						</div>

						<div className={`${styles.gridItem} ${styles.middleImg}`}>
							<div
								className={`${styles.image_container}`}
								style={{ border: "1.5px solid #0983B7" }}
							>
								<img
									className={styles.styled_image}
									src={img5}
									alt="Impact 5"
								/>
							</div>
						</div>
					</div>

					<Yellow className={styles.yellow} />

					<Blue className={styles.blue} />
				</div>
			</div>
		</section>
	);
};

export default Contact;
