import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/svg/Logo";
import { navLinks } from "../../data/constant";
import DarkModeSwitcher from "../darkMode/DarkModeSwitcher";
import styles from "./headerStyles.module.scss";

const Header: FC = (): JSX.Element => {
	const [open, setOpen] = useState(false);
	const location = useLocation();
	const [pathname, setPathname] = useState(location.pathname);

	useEffect(() => {
		setPathname(location.pathname);
	}, [location]);

	return (
		<nav
			className={`${styles.headerContainer} ${
				open ? styles.open : styles.close
			}`}
		>
			<div id="wrapper" className={styles.header}>
				<div className={styles.logoContainer}>
					<Link to="/" onClick={() => setOpen(false)}>
						<Logo className={styles.logo} />
					</Link>
					<button onClick={() => setOpen((prev) => !prev)}>
						{open ? (
							<svg viewBox="0 0 24 24">
								<path
									d="M18 6L6 18M6 6L18 18"
									// stroke="var(--black)"
									strokeWidth="3"
									strokeLinecap="square"
									strokeLinejoin="round"
								/>
							</svg>
						) : (
							<svg viewBox="0 0 36 35">
								<path
									d="M30.4267 9.56099L30.4267 9.561C30.1205 9.9062 29.6914 10.1136 29.2296 10.1165L14.3862 10.2114C13.9245 10.2144 13.4927 10.0125 13.1821 9.67125C12.8732 9.33174 12.7058 8.8801 12.7028 8.41782C12.6999 7.95554 12.8615 7.50179 13.1661 7.15836C13.4723 6.81315 13.9014 6.60577 14.3631 6.60282L29.2066 6.50791C29.6683 6.50496 30.1001 6.70684 30.4106 7.0481M30.4267 9.56099L30.4106 7.0481C30.4106 7.0481 30.4106 7.0481 30.4106 7.0481M30.4267 9.56099C30.7313 9.21756 30.8929 8.76381 30.8899 8.30153C30.887 7.83926 30.7196 7.38761 30.4106 7.0481M30.4267 9.56099L30.4106 7.0481M30.5379 26.9519C30.8425 26.6085 31.0041 26.1548 31.0011 25.6925C30.9982 25.2302 30.8308 24.7786 30.5218 24.439C30.2113 24.0978 29.7795 23.8959 29.3178 23.8989L12.9118 24.0038C12.4501 24.0067 12.021 24.2141 11.7148 24.5593C11.4102 24.9027 11.2486 25.3565 11.2515 25.8188C11.2545 26.281 11.4219 26.7327 11.7309 27.0722C12.0414 27.4135 12.4732 27.6153 12.9349 27.6124L29.3408 27.5075C29.8026 27.5045 30.2317 27.2971 30.5379 26.9519ZM29.2852 18.812C29.747 18.8091 30.1761 18.6017 30.4823 18.2565C30.7869 17.913 30.9485 17.4593 30.9455 16.997C30.9426 16.5347 30.7752 16.0831 30.4662 15.7436C30.1557 15.4023 29.7239 15.2004 29.2622 15.2034L6.60638 15.3482C6.14465 15.3512 5.71551 15.5586 5.40933 15.9038C5.10472 16.2472 4.94312 16.701 4.94608 17.1632C4.94903 17.6255 5.11642 18.0772 5.4254 18.4167C5.73596 18.7579 6.16772 18.9598 6.62945 18.9569L29.2852 18.812Z"
									// fill="var(--black)"
									// stroke="var(--black)"
								/>
							</svg>
						)}
					</button>
				</div>

				<div className={styles.links}>
					{navLinks.map((link, i) => (
						<Link
							key={i}
							onClick={() => setOpen(false)}
							to={link.to}
							style={{
								color: pathname === link.to ? "var(--blue)" : undefined,
							}}
						>
							{link.name}
						</Link>
					))}
				</div>

				<div className={styles.cta}>
					<DarkModeSwitcher />

					<a
						href={"https://africamissionsglobal.org/"}
						target="_blank"
						onClick={() => {
							setOpen(false);
						}}
						className="btn"
					>
						Donate
					</a>
				</div>
			</div>
		</nav>
	);
};

export default Header;
