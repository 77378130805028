import act1 from "./../assets/images/activities/1.jpg";
import act2 from "./../assets/images/activities/2.jpg";
import act3 from "./../assets/images/activities/3.jpg";
import img1 from "./../assets/images/hero/1.jpeg";
import img2 from "./../assets/images/hero/2.jpg";
import img3 from "./../assets/images/hero/3.jpg";

import team from "./../assets/images/team/1.jpg";

export const navLinks = [
	{
		name: "Home",
		to: "/",
	},
	{
		name: "About Us",
		to: "/about",
	},
	{
		name: "Contact Us",
		to: "/contact",
	},
	{
		name: "FAQs",
		to: "/faqs",
	},
	{
		name: "Gallery",
		to: "/gallery",
	},
];

export const heroContent = [
	{
		img: img1,
		content: "Invest in Africa's Future",
		subContent: " Empower young people ",
		subContent2: "through education,",
	},
	{
		img: img2,
		content: "Building a More Compassionate World ",
		subContent: null,
		subContent2: null,
	},
	{
		img: img3,
		content: "Be Part of the Change",
		subContent: null,
		subContent2: null,
	},
];

export const activitiesCard = [
	{
		img: act1,
		content: "Educational Workshops",
		subContent:
			"African Mission Global Harvesters (AMGH) offers a variety of workshops designed to equip young Africans with valuable skills, from computer literacy to financial management.",
	},
	{
		img: act2,
		content: "Community Service Projects",
		subContent:
			"African Mission Global Harvesters (AMGH) organizes community service projects that address local needs.  From building schools to cleaning up neighborhoods, these projects empower young people to make a positive difference.",
	},
	{
		img: act3,
		content: "Community Service Projects",
		subContent:
			"African Mission Global Harvesters (AMGH) organizes community service projects that address local needs.  From building schools to cleaning up neighborhoods, these projects empower young people to make a positive difference.",
	},
	{
		img: act1,
		content: "Community Service Projects",
		subContent:
			"African Mission Global Harvesters (AMGH) organizes community service projects that address local needs.  From building schools to cleaning up neighborhoods, these projects empower young people to make a positive difference.",
	},
];

export const testimonialsData = [
	{
		img: img1,
		name: "Zoe Mantis",
		role: "Founder, Alpha Group",
		comment:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
	},
	{
		img: img1,
		name: "Zoe Mantis",
		role: "Founder, Alpha Group",
		comment:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
	},
	{
		img: img1,
		name: "Zoe Mantis",
		role: "Founder, Alpha Group",
		comment:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
	},
	{
		img: img1,
		name: "Zoe Mantis",
		role: "Founder, Alpha Group",
		comment:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
	},
	{
		img: img1,
		name: "Zoe Mantis",
		role: "Founder, Alpha Group",
		comment:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
	},
	{
		img: img1,
		name: "Zoe Mantis",
		role: "Founder, Alpha Group",
		comment:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
	},
	{
		img: img1,
		name: "Zoe Mantis",
		role: "Founder, Alpha Group",
		comment:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
	},
];

export const whatWeDo = [
	"Children and youth education by conducting school outreaches featuring career talks, free summer school and tutorials for secondary school students in various subjects cutting across the junior and senior secondary curriculum.",
	"Extracurricular activities, youth empowerment and entrepreneurship seminars, including skill acquisition workshops, seminars on the value of education, Relationships, sex education, and Talents hunts, in which talents have been discovered and fully supported.",
	"Mission outreaches to various higher institutions of learning and communities, which featured crusades, house-to-house evangelism, sporting activities, film shows and other youth activities for the indigenes. These have yielded a tremendous harvest of souls.",
	"Church building support – raising funds for church building/infrastructure.",
	"Mission awareness rallies in schools, campuses and local markets.",
	"Visits to and support of Orphanages.",
	"Provision of boreholes for some rural communities lacking potable water.",
	"Medical mission outreaches in rural communities.",
	"Prison evangelism.",
	"Hospital evangelism.",
	"Market evangelism.",
];

export const teams = [
	{
		img: team,
		name: "Jane Doe",
	},
	{
		img: team,
		name: "Jane Doe",
	},
	{
		img: team,
		name: "Jane Doe",
	},
	{
		img: team,
		name: "Jane Doe",
	},
	{
		img: team,
		name: "Jane Doe",
	},
];

export const faqData = [
	{
		q: "What is AMGH?",
		a: "Africa Missions Global (AMG) is a full-fledged faith-based Not-for-profit International organization with the main aim of alleviating the plight of the underprivileged in Africa and around the world through appropriate community development and empowerment initiatives in line with the UN Sustainable Development Goals.",
	},
	{
		q: "Who is our patron and visionaire",
		a: "Our patron is Pastor E.A. Adeboye, the General Overseer of the Redeemed Christian Church of God World Wide. &&& Our Visionaire is Pastor Mrs Folu Adeboye, the wife of the general overseer of the Redeemed Christian Church of God World Wide.",
	},
	{
		q: "Where are your chapters located?",
		a: "Please click here to find location of our chapters.",
		link: "https://africamissionsglobal.org/about/our-chapters/",
	},
	{
		q: "Who are your partners?",
		a: "A partner is corporate organisation that is willing join forces with us to rescue and empower humanity via our projects.",
	},
	{
		q: "Who are your members?",
		a: "A member is an individual that is willing to join forces with AMG to rescue and empower humanity. Individuals can serve as volunteers by giving their time and resources to support the work. They can also serve as donors (in cash and in kind) to the charity organization.",
	},
	{
		q: "How can I partner?",
		a: "If you are a corporate organization, you can partner with AMG on this great cause. Kindly go to our website and fill the partnership form. The AMG secretariat will thereafter reach out to you.",
	},
	{
		q: "How can I become a member?",
		a: "Kindly click here to fill the membership form. The AMG secretariat will thereafter reach out to you.",
		link: "https://africamissionsglobal.org/volunteer/",
	},
	{
		q: "What are the donations used for?",
		a: "Donations are used to carry out charity projects in line with our focus areas (emergency intervention, epidemic relief and missions support) in different parts of Africa and the world where there is need.",
	},
	{
		q: "Can young people get involved?",
		a: "Absolutely, yes!  AMG has a youth arm under chapters called the Harvesters. They are committed to the vision and mission of AMG and they go all out to fulfil this mandate via youth-centric ideas and initiatives to impact young people. Please click here to fill the membership form.",
		link: "https://africamissionsglobal.org/volunteer/",
	},
];

export const missionYouTube = "https://www.youtube.com/embed/Dg_LeIjF_bw";
