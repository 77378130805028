import { FC, useEffect, useState } from "react";
import Logo from "../../assets/svg/Logo";

import { Link, useLocation } from "react-router-dom";
import { Facebook, Instagram, Twitter, Youtube } from "../../assets/svg/Social";
import { navLinks } from "../../data/constant";
import siteMetadata from "../../data/siteMetaData";
import styles from "./footerStyles.module.scss";

const Footer: FC = (): JSX.Element => {
	const location = useLocation();
	const [pathname, setPathname] = useState(location.pathname);

	useEffect(() => {
		setPathname(location.pathname);
	}, [location]);

	return (
		<div className={styles.footerContainer}>
			<div id="wrapper" className={styles.footer}>
				<Link to="/">
					<Logo className={styles.logo} />
				</Link>

				<div className={styles.links}>
					<div className={styles.top}>
						{navLinks.map((link, i) => (
							<Link
								key={i}
								to={link.to}
								style={{
									color: pathname === link.to ? "var(--blue)" : undefined,
								}}
							>
								{link.name}
							</Link>
						))}
					</div>

					<div className={styles.middle}>
						<Link to={siteMetadata.facebook}>
							<Facebook />
						</Link>

						<Link to={siteMetadata.instagram}>
							<Instagram />
						</Link>

						<Link to={siteMetadata.twitter} className={styles.twitter}>
							<Twitter />
						</Link>

						<Link to={siteMetadata.youtube}>
							<Youtube />
						</Link>
					</div>

					<div className={styles.bottom}>
						&copy; Global Mission Harvesters {new Date().getFullYear()}
					</div>
				</div>

				<span className={styles.logo2} />
			</div>
		</div>
	);
};

export default Footer;
