import { FC } from "react";
import { teams } from "../../../data/constant";
import styles from "./teamStyles.module.scss";

const Team: FC = (): JSX.Element => {
	return (
		<div id="wrapper" className={styles.team}>
			<h5>
				<span />

				<p>The Team</p>

				<span />
			</h5>

			<p>Meet some of our team members</p>

			<div className={styles.members}>
				{teams.map((team, i) => (
					<div key={i}>
						<img src={team.img} alt={team.name} />

						<p>{team.name}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default Team;
