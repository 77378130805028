import { FC } from "react";
import Logo from "../../../assets/svg/Logo";
import styles from "./aboutHeroStyles.module.scss";

const AboutHero: FC = (): JSX.Element => {
	return (
		<div id="wrapper" className={styles.aboutHero}>
			<h3>About Us</h3>

			<div className={styles.content}>
				<div>
					<h4>Our Vision</h4>

					<p style={{ marginBottom: "50px" }}>
						Africa’s Mission Global Havesters envisions a world where every
						individual has the opportunity to reach their full potential,
						regardless of their background or circumstances. We will become a
						leading organization for global education by developing innovative
						and efficient methods to deliver high-quality education programs to
						underserved communities worldwide. Through collaboration with local
						partners and a focus on sustainable solutions, AMGH will empower
						millions of children and break the cycle of poverty, creating a
						brighter future for generations to come. pen_spark
					</p>

					<h4>Our Mission</h4>

					<p>Empowering young Africans to reach their full potential.</p>
				</div>

				<Logo className={styles.logo} />
			</div>
		</div>
	);
};

export default AboutHero;
