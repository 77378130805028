import { FC } from "react";
import img from "../../../assets/images/about/mission.jpg";
import styles from "./focusStyles.module.scss";

const Focus: FC = (): JSX.Element => {
	return (
		<section className={styles.focus}>
			<div id="wrapper" className={styles.content}>
				<div>
					<img src={img} alt="About The African Mission Harvesters" />
				</div>

				<div>
					<h4>About The African Mission Harvesters</h4>

					<p>
						The Youth wing of Africa Missions, known as Africa Missions Global
						Harvesters (AMGH) is currently under the respective Chapters and is
						managed through 6 (six) groupings: Primary, Secondary, University,
						NYSC, Non-School, and professional groups. AMGH is a home for all
						young people from the age of 10, with no class distinction and full
						acceptance of every youth who qualifies to be a member.
					</p>

					<br />
					<br />

					<p>
						The Harvesters boasts of a vibrant Youth wing with branches in
						Nigeria and Ghana, and fast moving to The Americas and other
						continents.
					</p>

					<h4 style={{ marginTop: "22px" }}>Our Focus</h4>

					<p>
						It was designed to expose young people to the culture of missions
						and missionary challenges with a focus on the youth of Africa.
					</p>
				</div>
			</div>
		</section>
	);
};

export default Focus;
