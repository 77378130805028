import { FC } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { heroContent } from "../../../data/constant";
import styles from "./heroStyles.module.scss";

const Hero: FC = (): JSX.Element => {
	return (
		<section className={styles.hero}>
			<Swiper navigation={true} modules={[Navigation]} className="mySwiper">
				{heroContent.map((hero, i) => (
					<SwiperSlide key={i}>
						<div id="wrapper" className={styles.heroContent}>
							<div className={styles.overLay} />

							<img
								alt={hero.content}
								src={hero.img}
								className={styles.overLay_img}
							/>

							<div className={styles.text}>
								<h2>{hero.content}</h2>

								{hero.subContent && <h3>{hero.subContent}</h3>}

								{hero.subContent2 && (
									<h3 className={styles.gradient_test}>{hero.subContent2}</h3>
								)}
							</div>

							<div className={styles.cta}>
								<a
									href={"https://africamissionsglobal.org/"}
									target="_blank"
									className="btn"
								>
									Donate
								</a>

								<a
									href={"https://africamissionsglobal.org/volunteer/"}
									target="_blank"
									className="btn"
									style={{ background: "transparent" }}
								>
									Volunteer
								</a>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};

export default Hero;
