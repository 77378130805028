import { FC } from "react";
import img1 from "../../../assets/images/gallery/16.jpg";
import img2 from "../../../assets/images/gallery/17.jpg";
import img3 from "../../../assets/images/gallery/18.jpg";
import img4 from "../../../assets/images/gallery/19.jpg";
import { whatWeDo } from "../../../data/constant";
import styles from "./whatStyles.module.scss";

const What: FC = (): JSX.Element => {
	return (
		<section id="wrapper" className={styles.whatContainer}>
			<h4>What we do</h4>

			<div className={styles.what}>
				<div className={styles.left}>
					<img src={img1} alt="What we do" />

					<img src={img2} alt="What we do" />

					<img src={img3} alt="What we do" />

					<img src={img4} alt="What we do" />
				</div>

				<ul className={styles.right}>
					{whatWeDo.map((what, i) => (
						<li key={i}>
							<svg
								viewBox="0 0 20 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M10.707 1.22931C10.5195 1.04184 10.2652 0.936523 10 0.936523C9.73487 0.936523 9.48056 1.04184 9.29303 1.22931L6.29303 4.22931C6.10556 4.41684 6.00024 4.67115 6.00024 4.93631C6.00024 5.20147 6.10556 5.45578 6.29303 5.64331L9.29303 8.64331C9.48056 8.83078 9.73487 8.9361 10 8.9361C10.2652 8.9361 10.5195 8.83078 10.707 8.64331L13.707 5.64331C13.8945 5.45578 13.9998 5.20147 13.9998 4.93631C13.9998 4.67115 13.8945 4.41684 13.707 4.22931L10.707 1.22931ZM9.29303 13.2293C9.48056 13.0418 9.73487 12.9365 10 12.9365C10.2652 12.9365 10.5195 13.0418 10.707 13.2293L13.707 16.2293C13.8945 16.4168 13.9998 16.6711 13.9998 16.9363C13.9998 17.2015 13.8945 17.4558 13.707 17.6433L10.707 20.6433C10.5195 20.8308 10.2652 20.9361 10 20.9361C9.73487 20.9361 9.48056 20.8308 9.29303 20.6433L6.29303 17.6433C6.10556 17.4558 6.00024 17.2015 6.00024 16.9363C6.00024 16.6711 6.10556 16.4168 6.29303 16.2293L9.29303 13.2293ZM15.293 7.22931C15.4806 7.04184 15.7349 6.93652 16 6.93652C16.2652 6.93652 16.5195 7.04184 16.707 7.22931L19.707 10.2293C19.8945 10.4168 19.9998 10.6711 19.9998 10.9363C19.9998 11.2015 19.8945 11.4558 19.707 11.6433L16.707 14.6433C16.5195 14.8308 16.2652 14.9361 16 14.9361C15.7349 14.9361 15.4806 14.8308 15.293 14.6433L12.293 11.6433C12.1056 11.4558 12.0002 11.2015 12.0002 10.9363C12.0002 10.6711 12.1056 10.4168 12.293 10.2293L15.293 7.22931ZM3.29303 7.22931C3.48056 7.04184 3.73487 6.93652 4.00003 6.93652C4.26519 6.93652 4.5195 7.04184 4.70703 7.22931L7.70703 10.2293C7.8945 10.4168 7.99982 10.6711 7.99982 10.9363C7.99982 11.2015 7.8945 11.4558 7.70703 11.6433L4.70703 14.6433C4.5195 14.8308 4.26519 14.9361 4.00003 14.9361C3.73487 14.9361 3.48056 14.8308 3.29303 14.6433L0.293031 11.6433C0.105559 11.4558 0.000244141 11.2015 0.000244141 10.9363C0.000244141 10.6711 0.105559 10.4168 0.293031 10.2293L3.29303 7.22931Z"
									fill="#054F6E"
								/>
							</svg>

							<p>{what}</p>
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};

export default What;
