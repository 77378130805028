// import
import { FC } from "react";
import AboutHero from "../../screens/about/aboutHero/AboutHero";
import Focus from "../../screens/about/focus/Focus";
import Invest from "../../screens/about/invest/Invest";
import Team from "../../screens/about/team/Team";
import What from "../../screens/about/what/What";

const AboutPage: FC = (): JSX.Element => {
	return (
		<div>
			<AboutHero />

			<Focus />

			<What />

			<Invest />

			<Team />
		</div>
	);
};

export default AboutPage;
