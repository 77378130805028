import { FC } from "react";
import { missionYouTube } from "../../../data/constant";
import styles from "./missionStyles.module.scss";

const Mission: FC = (): JSX.Element => {
	return (
		<section id="wrapper" className={styles.mission}>
			<div className={styles.left}>
				<h3>
					Africa Missions Global extends all round support to the people of
					Ankoko Community.
				</h3>

				<p>
					Africa Missions will be the first organization to undertake any
					community-based development project in Ankoko Mission Field, Niger
					State, Nigeria. The Redeemers Basic School and church are the first
					infrastructural development projects that will meet the desperate
					needs of the community. We have maintained a consistent monthly
					disbursement for the school’s operating expenses and the church is
					growing rapidly.
				</p>
			</div>

			<div className={styles.right}>
				<iframe
					className={styles.youtube}
					// width="585"
					// height="329"
					src={missionYouTube}
					title="Africa Missions Global: Internally Displaced Persons"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerPolicy="strict-origin-when-cross-origin"
					allowFullScreen
				></iframe>
			</div>
		</section>
	);
};

export default Mission;
