export const Blue = ({ className }: { className: string }) => (
	<svg
		className={className}
		width="120"
		height="120"
		viewBox="0 0 120 120"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			width="10"
			height="10"
			transform="translate(37.8396 0.461426) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(56.065 8.69775) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(74.2903 16.9341) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(92.5157 25.1704) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(110.741 33.4067) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(29.6033 18.6865) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(47.8286 26.9229) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(66.054 35.1592) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(84.2793 43.3955) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(102.505 51.6318) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(21.367 36.9121) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(39.5923 45.1484) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(57.8177 53.3848) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(76.043 61.6211) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(94.2684 69.8574) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(13.1307 55.1372) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(31.356 63.3735) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(49.5814 71.6099) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(67.8067 79.8462) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(86.032 88.0825) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(4.89435 73.3628) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(23.1197 81.5991) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(41.345 89.8354) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(59.5704 98.0718) rotate(24.319)"
			fill="#0983B7"
		/>
		<rect
			width="10"
			height="10"
			transform="translate(77.7957 106.308) rotate(24.319)"
			fill="#0983B7"
		/>
	</svg>
);

export const Yellow = ({ className }: { className: string }) => (
	<svg
		className={className}
		width="102"
		height="106"
		viewBox="0 0 102 106"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(41.8784 0.0732422) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(52.7538 6.4668) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(63.6291 12.8599) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(74.5044 19.2534) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(85.3798 25.6465) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(96.2551 32.04) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(35.485 10.9487) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(46.3604 17.3423) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(57.2357 23.7354) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(68.1111 30.1289) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(78.9864 36.522) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(89.8618 42.9155) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(29.0917 21.8237) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(39.9671 28.2173) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(50.8424 34.6104) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(61.7178 41.0039) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(72.5931 47.397) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(83.4685 53.7905) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(22.6984 32.6992) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(33.5738 39.0923) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(44.4491 45.4858) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(55.3244 51.8794) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(66.1998 58.2725) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(77.0751 64.666) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(16.3051 43.5747) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(27.1804 49.9683) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(38.0557 56.3613) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(48.9311 62.7549) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(59.8064 69.1479) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(70.6818 75.5415) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(9.91174 54.4497) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(20.7871 60.8428) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(31.6624 67.2363) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(42.5378 73.6299) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(53.4131 80.0229) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(64.2885 86.4165) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(3.5184 65.3252) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(14.3937 71.7188) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(25.269 78.1118) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(36.1444 84.5054) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(47.0198 90.8984) rotate(30.4502)"
			fill="#FBBB00"
		/>
		<rect
			width="6.30769"
			height="6.30769"
			transform="translate(57.8951 97.292) rotate(30.4502)"
			fill="#FBBB00"
		/>
	</svg>
);
