// import

import { FC } from "react";
import Activities from "../screens/home/activities/Activities";
import Hero from "../screens/home/hero/Hero";
import Impact from "../screens/home/impact/Impact";
import Mission from "../screens/home/mission/Mission";
import Testimonials from "../screens/home/testimonials/Testimonials";

const HomePage: FC = (): JSX.Element => {
	return (
		<div>
			<Hero />

			<Mission />

			<Activities />

			<Impact />

			<Testimonials />
		</div>
	);
};

export default HomePage;
