import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/headers/Header";

const RootLayout = () => {
	return (
		<div id="mainContainer">
			<header id="header">
				<Header />
			</header>

			<main id="main">
				<Outlet />
			</main>

			<footer id="main">
				<Footer />
			</footer>
		</div>
	);
};

export default RootLayout;
