import { FC } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { activitiesCard } from "../../../data/constant";
import useWindowSize from "../../../hooks/useWindowSize";
import styles from "./activitiesStyles.module.scss";

const Activities: FC = (): JSX.Element => {
	const { width } = useWindowSize();

	return (
		<section className={styles.activities}>
			<div id="wrapper">
				<h4>Activities</h4>

				<p className={styles.subHeader}>
					The Harvesters have been involved in many activities and accomplished
					various projects, some of which are indicated below
				</p>

				<Swiper
					pagination={{
						dynamicBullets: true,
						clickable: true,
					}}
					modules={[Pagination]}
					className={styles.mySwiper}
					slidesPerView={(width > 1300 && 3) || (width > 940 && 2) || 1}
					spaceBetween={30}
				>
					{activitiesCard.map((activity, i) => (
						<SwiperSlide
							key={i}
							style={{ display: "grid", placeItems: "center" }}
						>
							<div className={styles.card}>
								<div style={{ width: "100%", height: "319.65px" }}>
									<img
										alt={activity.content}
										src={activity.img}
										className={styles.overLay_img}
									/>
								</div>

								<h5>{activity.content}</h5>

								<p>{activity.subContent}</p>
							</div>
						</SwiperSlide>
					))}
				</Swiper>

				<div className={styles.cta}>
					<Link to={"/"} className="btn">
						Learn More
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Activities;
