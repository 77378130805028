import { FC, useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { testimonialsData } from "../../../data/constant";
import useWindowSize from "../../../hooks/useWindowSize";
import styles from "./testimonialsStyles.module.scss";

const Testimonials: FC = (): JSX.Element => {
	const { width } = useWindowSize();
	const [latestWidth, setLatestWidth] = useState(0);

	useEffect(() => {
		setLatestWidth(width);
	}, [width]);

	return (
		<section className={styles.testimonials}>
			<h5 id="wrapper">Testimonials from some lives we have touched</h5>

			<Swiper
				slidesPerView={(width > 1300 && 3) || (width > 700 && 2) || 1}
				spaceBetween={30}
				centeredSlides={width > 700}
				pagination={{
					dynamicBullets: true,
					clickable: true,
				}}
				modules={[Pagination]}
				className={styles.mySwiper}
				id={(latestWidth < 700 && "wrapper") || ""}
			>
				{testimonialsData.map((test, i) => (
					<SwiperSlide
						key={i}
						style={{ display: "grid", placeItems: "center" }}
					>
						<div className={styles.card}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									gap: 21,
								}}
							>
								<div className={styles.imgContainer}>
									<img
										className={styles.styled_image}
										src={test.img}
										alt="Testimonials 5"
									/>
								</div>

								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
										justifyContent: "flex-start",
										gap: 5,
									}}
								>
									<h6>{test.name}</h6>

									<p>{test.role}</p>
								</div>
							</div>

							<p>{test.comment}</p>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};

export default Testimonials;
