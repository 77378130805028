import { FC } from "react";
import img from "../../../assets/images/hero/1.jpeg";

import {
	InvestSvg1,
	InvestSvg2,
	InvestSvg3,
} from "../../../assets/svg/InvestSvg";
import styles from "./investStyles.module.scss";

const Invest: FC = (): JSX.Element => {
	return (
		<section className={styles.invest}>
			<div className={styles.top}>
				<div className={styles.overLay} />

				<img alt="overLay" src={img} className={styles.overLay_img} />

				<h4>Invest in Young Lives: Make a Difference</h4>

				<h5>Together, We Can Make a Difference</h5>

				<div className={styles.cta}>
					<a
						href={"https://africamissionsglobal.org/"}
						target="_blank"
						className="btn"
					>
						Donate
					</a>

					<a
						href={"https://africamissionsglobal.org/volunteer/"}
						target="_blank"
						className="btn"
						style={{ background: "transparent" }}
					>
						Volunteer
					</a>
				</div>
			</div>

			<div className={styles.bottom}>
				<h4>Why Get Involved?</h4>

				<div className={styles.info} id="wrapper">
					<div>
						<InvestSvg1 />

						<p>
							<span>Empower Young Lives and Make a Lasting Impact:</span> By
							volunteering your time or donating resources, you directly
							contribute to AMGH&apos;s mission of empowering young Africans.
							You&apos;ll be helping them reach their full potential, which has
							a ripple effect on their communities and the future of Africa.
						</p>
					</div>

					<div>
						<InvestSvg2 />

						<p>
							<span>Invest in the Future of Africa:</span> Africa&apos;s youth
							population is booming. By supporting AMGH, you&apos;re investing
							in the continent&apos;s future leaders, entrepreneurs, and
							changemakers. A well-educated and empowered youth is crucial for
							Africa&apos;s development and prosperity.
						</p>
					</div>

					<div>
						<InvestSvg2 />

						<p>
							<span>Develop Your Skills and Gain Valuable Experience: </span>{" "}
							Volunteering with AMGH can provide you with valuable skills and
							experience. You could mentor young people, develop workshops, or
							assist with event planning. These experiences can enhance your
							resume and personal growth.
						</p>
					</div>

					<div>
						<InvestSvg3 />

						<p>
							<span>Become Part of a Supportive Community:</span> AMGH fosters a
							sense of community among volunteers, donors, and young
							participants. By getting involved, you&apos;ll connect with
							like-minded individuals who share your passion for supporting
							young Africans and making a difference.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Invest;
