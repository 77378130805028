import { FC, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import img from "../../assets/images/team/all2.jpg";
import styles from "./galleryStyles.module.scss";
import { images } from "./images";

const slides = images.map(({ original, width, height }) => ({
	src: original,
	width,
	height,
}));

const GalleryScreen: FC = (): JSX.Element => {
	const [index, setIndex] = useState(-1);

	const handleClick = (index: number) => setIndex(index);

	return (
		<div className={styles.gallery}>
			<div className={styles.top}>
				<div className={styles.overLay} />

				<img alt="overLay" src={img} className={styles.overLay_img} />

				<h4>A window into our work, a glimpse into our hearts</h4>
			</div>

			<div className={styles.bottom} id="wrapper">
				<Gallery
					margin={15}
					images={images}
					onClick={handleClick}
					enableImageSelection={false}
					rowHeight={400}
					maxRows={images.length}
				/>

				<Lightbox
					slides={slides}
					open={index >= 0}
					index={index}
					close={() => setIndex(-1)}
				/>
			</div>
		</div>
	);
};

export default GalleryScreen;
