// please update required information
const siteMetadata = {
	title: "Africa’s Mission Global",
	description: "Empowering young Africans to reach their full potential.",
	headerTitle: "Africa’s Mission Global",
	author: "Africa’s Mission Global",
	theme: "#000000",
	siteUrl: "http://localhost:3000/",
	siteLogo: "/assets/images/logo.png",
	socialBanner: "/assets/images/logo.png",
	email: "amnharvesters@gmail.com",
	github: "",
	twitter: "https://twitter.com/AfriMissions",
	facebook: "https://www.facebook.com/afrimissions",
	instagram: "https://www.instagram.com/AfriMissions",
	youtube: "https://www.youtube.com/channel/UCK-Itj6Dzs349OxYAJullTg",
	linkedin: "",
	language: "en-us",
	locale: "en-US",
	keywords: [
		"Africa Missions Global Harvesters",
		"Nigeria tech entrepreneur",
		"Empower young people",
	],
};

export default siteMetadata;
