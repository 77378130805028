import * as Accordion from "@radix-ui/react-accordion";

import { FC } from "react";
import img from "../../assets/images/boy.png";
import { faqData } from "../../data/constant";
import styles from "./faqsStyles.module.scss";

const Faqs: FC = (): JSX.Element => {
	return (
		<div id="wrapper" className={styles.faqs}>
			<Accordion.Root type="single" className={styles.accordion} collapsible>
				{faqData.map((faq, i) => (
					<Accordion.Item value={i.toString()} key={i} className={styles.item}>
						<Accordion.Header>
							<Accordion.Trigger className={styles.trigger}>
								<p className={styles.title}>{faq.q}</p>

								<svg
									// width="29"
									// height="15"
									viewBox="0 0 29 15"
									fill="none"
									className={styles.icon}
								>
									<path
										d="M24.7968 2.54142L15.5982 10.4298C15.3975 10.6027 15.1236 10.7033 14.8361 10.7096C14.5486 10.7159 14.2707 10.6275 14.0626 10.4635L4.52668 2.98807C4.31733 2.82418 4.03846 2.73589 3.75013 2.74222C3.4618 2.74855 3.18708 2.84899 2.98512 3.02191C2.88566 3.10641 2.80734 3.2065 2.75477 3.3163C2.7022 3.42611 2.67645 3.54339 2.67904 3.66125C2.68163 3.7791 2.7125 3.89514 2.76984 4.00253C2.82717 4.10992 2.90981 4.20648 3.01289 4.28653L12.5468 11.762C13.1747 12.2531 14.0109 12.5177 14.8754 12.4987C15.7399 12.4798 16.5637 12.1788 17.1695 11.6605L26.3662 3.77387C26.4659 3.68934 26.5445 3.58914 26.5972 3.47918C26.65 3.36922 26.6758 3.25173 26.6732 3.13368C26.6707 3.01562 26.6397 2.89939 26.5822 2.79185C26.5246 2.68431 26.4417 2.58765 26.3384 2.50758C26.129 2.34369 25.8501 2.2554 25.5618 2.26173C25.2735 2.26806 24.9988 2.3685 24.7968 2.54142Z"
										// stroke="#D9D8D7"
										strokeWidth="4.41538"
									/>
								</svg>
							</Accordion.Trigger>
						</Accordion.Header>

						<Accordion.Content className={styles.content}>
							<div>
								{faq.a.split("&&&").map((answer, i) => {
									if (!faq.link) return <p key={i}>{answer}</p>;

									const parts = answer.split(/(click here)/i);

									return (
										<p key={i}>
											{parts.map((part, index) =>
												part.toLowerCase() === "click here" ? (
													<a key={index} target="_blank" href={faq.link}>
														click here
													</a>
												) : (
													part
												)
											)}
										</p>
									);
								})}
							</div>
						</Accordion.Content>
					</Accordion.Item>
				))}
			</Accordion.Root>

			<div className={styles.right}>
				<img alt="Boy" src={img} />

				<p>
					Still in need of help? <br />
					Email amnharvesters@gmail.com
				</p>
			</div>
		</div>
	);
};

export default Faqs;
